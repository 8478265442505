.ad-header {
    background: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

th.invoice-id {
    font-weight: normal;
}

.table {
    margin-bottom: 0!important;
}

.canvas-container{
    height: 60vh;
}



.card-body {
    flex: 1 1 auto;
}

.mb-dashboard-offers {
    margin-top: 7px;
    /* margin-bottom: 70px; */
}

.mb-campaign-container {
    margin-left: -3px;
    margin-right: -3px;
}

@media(max-width: 576px) {
    .mb-dashboard-offers {
        margin-top: .5rem!important;
    }
}