.country-container.open {
    height: auto;
    overflow: auto;
    padding-bottom: 25px;
}

.country-container.closed {
    height: 100px;
    overflow: hidden;
}

.country-container.closed.short {
    height: auto;
    overflow: hidden;
}

.country-overlay {
    padding-top: 15px;
    bottom: 0;
    width: 100%;
}

.country-overlay.open {
    background: none;
}

.country-overlay.closed {
    background: #e5e5e5;
    /* Old browsers */
    background: -moz-linear-gradient(top, RGBA(255, 255, 255, 0), #ffffff 60%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, RGBA(255, 255, 255, 0), #ffffff 60%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, RGBA(255, 255, 255, 0), #ffffff 60%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#000000', GradientType=0);
    /* IE6-9 */
}
