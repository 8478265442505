.Footer {
    /* margin-left: 229px; */
    overflow: hidden;
    /* max-width: calc(100%-450px); */
    padding-left: .5rem!important;
    padding-right: .5rem!important;
    font-size: 14px;
    margin-right: 238px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (max-width: 1200px){
    .Footer {
        margin-right: 8px;
    }
}

@media(max-width: 768px){
    .Footer {
        margin-bottom: 60px;
    }
}