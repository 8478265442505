.UserDetails {
    background: white;
    overflow: hidden;
    padding: 0.5rem!important;
    text-align: left;
}

@media (max-width: 768px){
    .UserDetails {
        width: 100%;
    }
}