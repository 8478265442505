html{
  height:100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529;
  bottom: 0;
  height:100%;
  background-color:#eaeef2 !important;
}

div#root{
  height:100%;
}

.text-mblight{
  color: RGBA(0,0,0,0.25);
}

.bebas{
  font-family: bebas-neue, sans-serif; 
}