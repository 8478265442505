.system-message-container{
    position:fixed;
    z-index:99999999;
    width:100%;
}

.system-message-container-holder{
    display:inline-block;
    margin:auto;
}

.system-message{    
    top:50px;
    padding:0.5rem 1rem;
    margin-top:1rem;
    border-radius:4px;
    box-shadow:0px 2px 5px RGBA(0,0,0,0.2);
    font-weight:bold;
}

.system-message.success{
    background:#00d961;
    color:RGBA(0,0,0,0.5);
}

.system-message.error{
    background:red;
    color:white;
}

.systemMessageIn-appear {
    opacity: 0;
}

.systemMessageIn-appear.systemMessageIn-appear-active {
    /* opacity: 0.3; */
    /* transition: all 1s ease-in-out; */
    opacity: 0;
}

.systemMessageIn-enter {
    /* opacity: 0.6; */
    opacity: 0;
}

.systemMessageIn-enter.systemMessageIn-enter-active {
    opacity: 1;
    transition: all .5s ease-in-out;
}

.systemMessageIn-enter-done {    
    /* transition-duration: 300ms; */
    transition: all .5s ease-in-out;
}

.systemMessageIn-exit{
  opacity: 0;
}

.systemMessageIn-exit.systemMessageIn-exit-active {
  opacity: 0;
  transition: all .5s ease-in-out;
}

.systemMessageIn-exit-done {    
  /* transition-duration: 300ms; */
  transition: all .5s ease-in-out;
}