.Content {
    margin-left: 220px;
    padding-left: .5rem!important;
    padding-right: .5rem!important;
    font-size: 14px;
    margin-right: 0px!important;
    margin-top: 50px;
    text-align: start;
}

@media (max-width: 1200px){
    .Content {
        margin-right: 0;
    }
}

@media(max-width: 768px){
    .Content {
        margin-top: 50px;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 0;
    }
}