.mb-underline {
    display: inline-block;
}

.mb-underline {
    content: " ";
    border-bottom: 3px solid RGBA(224, 37, 39, 1);
    width: 8%;
}

.max-width-500{
    max-width: 500px;
}

.max-width-750{
    max-width: 750px;
}

.modal-content{
    font-size: 0.875rem!important;
}

.numberCircle{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 5px;
    background: RGBA(224,37,39,1);
    border: none;
    color: white;
    text-align: center;
    font: 16px Arial, sans-serif;
}