th.sortable{
    cursor: pointer;
    white-space: nowrap;
}

.fixed-col{
    max-width:100px;
}

.fixed-col div{
    word-wrap:initial;
    white-space:nowrap;
    overflow-x:scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.fixed-col div::-webkit-scrollbar { 
    display: none;
}