.login-container{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
}

div.signup-container{
  height:100%;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 50px 15px 15px 15px;
  margin: auto;
  z-index:9999 !important;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .checkbox {
  font-weight: 400;
}

.login-video{
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}