.sidebar .left-nav-bar {
    position: fixed;
    top: 25;
    height: calc(100vh-48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    color: #6c757d!important;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    min-width: 150px;
    background-color: #181a1d;
}

.mb-sidebar {
    width: 220px;
}

.sidebar-header {
    font-size: 12px;
    text-transform: uppercase;
    padding-left: 1rem!important;
    padding-right: 1rem!important;
    margin-top: 1.5rem!important;
    justify-content: space-between!important;
    display: flex!important;
    align-items: center!important;
}

.leftbar-btn {
    color: #6c757d!important;
    text-decoration: none!important;
    /* font-weight: 500; */
}

.nav-link {
    display: block;
    /* padding: .5rem 1rem; */
}

.link-title {
    font-size: 14px;
    font-weight: 500;
}

.sidebar a {
    display: inline-block;
    color: #6c757d;
    padding-right: 0;
}

.sidebar a:hover {
    text-decoration: none;
    color: #8d969e;
}

.nav-link.active-nav {
    color: #007bff;
}

.nav-link.active-nav:hover {
    color: #007bff;
}

.contact-modal {
    max-width: 300px;
    font-size: 14px;
    color: black!important;
}

@media(max-width: 768px) {
    .sidebar {
        display: none;
    }
}