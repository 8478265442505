.Toolbar {
    background-color: #fe3234;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-top {
    position: fixed;
}

.navbar-brand {
    width: 220px;
    background-color: #e92a2a;
}

.Toolbar .mb-icon {
    height: 50px;
    /* align-content: center; */
    margin: 0px;
}

.navbar {
    text-align: center;
}

@media(max-width: 768px) {
    .desktopOnly {
        display: none;
    }
}