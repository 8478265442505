.mobile-only {
    display: none;
}

.bottom-nav{
    display: none;
}

@media(max-width: 768px) {
    .mobile-only {
        display: initial;
    }

    .bottom-nav{
        background-color: #181a1d;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
        display: initial;
        height: 50px;
    }
}